import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";
import { fetchApi } from "../../utils/fetch-api";

const {
  squireApiConfig: { url },
} = window.env;

export default class CompaniesApiService {
  getCompaniesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/companies/`,
      insertUrl: `${url}/v1/companies/`,
      updateUrl: `${url}/v1/companies/`,
      deleteUrl: `${url}/v1/companies/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getCompanyDetails(companyId) {
    const fetchUrl = `${url}/v1/companies/${companyId}`;
    return await fetchApi(fetchUrl, "GET");
  }

  async createCompany(values) {
    const fetchUrl = `${url}/v1/companies/`;
    const formBody = new URLSearchParams({
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "POST", formBody);
  }

  async updateCompany(companyId, values) {
    const fetchUrl = `${url}/v1/companies/`;
    const formBody = new URLSearchParams({
      key: companyId,
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "PUT", formBody);
  }

  getIndustryTypesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/companies/industry-types`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }
}