import React,
{
  useRef,
  useState,
}
from "react";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Scrolling,
  Button,
  Editing,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import Form, {
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import TabPanel from "devextreme-react/tab-panel";
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";
import { ManyToManyGrid } from '../../components/many-to-many-grid/many-to-many-grid';

export default function Companies(props) {
  const companiesApiService = new props.companyApiService();
  const companiesDataSource = companiesApiService.getCompaniesDataSource();
  const industryTypeDataSource = companiesApiService.getIndustryTypesDataSource();

  const companiesDataGridName = "CompaniesDataGrid";
  const companiesDataGridRef = useRef(null);

  const [showCompanyEditForm, setShowCompanyEditForm] = useState();
  const [editingCompanyViewData, setEditingCompanyViewData] = useState();
  const [showEstimateCategories, setShowEstimateCategories] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const editingCompanyDtoData = useRef(
    {
      estimateCategories: {
        toAdd: [],
        toRemove: [],
      }
    }
  );
  const editFormTitle = useRef('');
  const editFormRef = useRef();
  const addCategoriesFormTitle = useRef('');


  const industryTypeEditorOptions = {
    dataSource: industryTypeDataSource,
    searchEnabled: true,
    displayExpr: "value",
    valueExpr: "id",
  }

  const subtradeEditorOptions = {
    hint: "Select if you want this company to show in the subtrades drop down on the estimate sheet.",
    onValueChanged(e) {
      setShowEstimateCategories(e.value);
    }
  }

  const companyEditFormSaveButtonOptions = {
    text: "Save",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      companyEditFormSaveButtonOnClick();
    }
  }

  async function companyEditFormSaveButtonOnClick() {
    var formInstance = editFormRef.current.instance;
    var validationResult = formInstance.validate();
    if (validationResult.isValid) {
      await saveCompanyData()
        .then(() => {
          cleanupAndCloseEditForm();
        });
    }
  }

  function cleanupAndCloseEditForm() {
    setIsCreate(false);
    setShowCompanyEditForm(false);
    setEditingCompanyViewData();
    resetCompanyDto();
  }

  async function saveCompanyData() {
    if (isCreate) {
      await companiesApiService.createCompany(editingCompanyDtoData.current);
    }
    else {
      await companiesApiService.updateCompany(editingCompanyViewData.id, editingCompanyDtoData.current);
    }
  }

  const companyEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      companyEditFormCancelButtonOnClick();
    }
  }

  function companyEditFormCancelButtonOnClick() {
    cleanupAndCloseEditForm();
  }

  function companyEditFormFieldDataChanged(e) {
    if (e.dataField === "displayName") {
      var dtoHasName = editingCompanyDtoData.current.hasOwnProperty("displayName");
      if (!dtoHasName) {
        Object.defineProperty(editingCompanyDtoData.current, "displayName", { value: "", writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.displayName = e.value;
    }
    if (e.dataField === "description") {
      var dtoHasDescription = editingCompanyDtoData.current.hasOwnProperty("description");
      if (!dtoHasDescription) {
        Object.defineProperty(editingCompanyDtoData.current, "description", { value: "", writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.description = e.value;
    }
    if (e.dataField === "industryTypeId") {
      var dtoHasIndustryTypeId = editingCompanyDtoData.current.hasOwnProperty("industryTypeId");
      if (!dtoHasIndustryTypeId) {
        Object.defineProperty(editingCompanyDtoData.current, "industryTypeId", { value: -1, writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.industryTypeId = e.value;
    }
    if (e.dataField === "subtrade") {
      var dtoHasSubtrade = editingCompanyDtoData.current.hasOwnProperty("subtrade");
      if (!dtoHasSubtrade) {
        Object.defineProperty(editingCompanyDtoData.current, "subtrade", { value: false, writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.subtrade = e.value;
    }
    if (e.dataField === "supplier") {
      var dtoHasSupplier = editingCompanyDtoData.current.hasOwnProperty("supplier");
      if (!dtoHasSupplier) {
        Object.defineProperty(editingCompanyDtoData.current, "supplier", { value: false, writable: true, enumerable: true })
      }
      editingCompanyDtoData.current.supplier = e.value;
    }
    if (e.dataField === "sageName") {
      var dtoHasOperatingAs = editingCompanyDtoData.current.hasOwnProperty("sageName");
      if (!dtoHasOperatingAs) {
        Object.defineProperty(editingCompanyDtoData.current, "sageName", { value: "", writable: true, enumerable: true });
      }
      editingCompanyDtoData.current.sageName = e.value;
    }
  }

  const sageNameEditorOptions = {
    disabled: true
  }

  const manyToManyGridColumns = [
    <Column
      dataField="name"
      sortIndex={0}
      sortOrder="asc"
    />
  ]

  function renderCompanyEditForm() {
    if (showCompanyEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showCompanyEditForm}
          showCloseButton={true}
          title={editFormTitle.current}
          onHiding={() => {
            cleanupAndCloseEditForm();
          } }
        >
          <TabPanel>
            <Item
              title="Properties"
            >
              <Form
                id="editingCompanyForm"
                formData={editingCompanyViewData}
                onFieldDataChanged={companyEditFormFieldDataChanged}
                ref={editFormRef}
              >
                <GroupItem>
                  <Item dataField="displayName">
                    <RequiredRule />
                  </Item>
                  <Item
                    dataField="sageName"
                    editorOptions={sageNameEditorOptions}
                  />
                  <Item dataField="description" />
                  <Item
                    dataField="industryTypeId"
                    editorType="dxSelectBox"
                    editorOptions={industryTypeEditorOptions}
                  >
                    <Label
                      text="Industry"
                    />
                  </Item>
                  <Item
                    dataField="supplier"
                  />
                  <Item
                    dataField="subtrade"
                    editorType="dxCheckBox"
                    editorOptions={subtradeEditorOptions}
                  />
                </GroupItem>
                <div className="spacedDiv" />
                <GroupItem
                  caption="Estimate Categories"
                  visible={showEstimateCategories}
                >
                  <div className="spacedDiv">This grid displays all the Estimate Categories that will display this company under the 'Subtrade' group on the Estimate Sheet.</div>
                  <ManyToManyGrid
                    relatedGridDataSource={editingCompanyViewData.relatedCategories.$values}
                    unrelatedGridDataSource={editingCompanyViewData.unrelatedCategories.$values}
                    addButtonText="Add Categories to Subtrade..."
                    removeMultipleRelatedButtonText="Remove Selected Cagegories..."
                    removeMultipleMessage="Are you sure you want to remove the selected Categories from this Subtrade?"
                    confirmDeleteMessage="Are you sure you want to remove this Category from this Subtrade?"
                    addUnrelatedPopupHeaderTitle={addCategoriesFormTitle.current}
                    relatedObjectsToAddCollection={editingCompanyDtoData.current.estimateCategories.toAdd}
                    relatedObjectsToRemoveCollection={editingCompanyDtoData.current.estimateCategories.toRemove}
                    relatedGridColumns={manyToManyGridColumns}
                    unrelatedGridColumns={manyToManyGridColumns}
                  />
                </GroupItem>
              </Form>
            </Item>
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={companyEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={companyEditFormCancelButtonOptions}
          />
        </Popup>
      )
    }
  }

  async function companyGridOnEditingStart(e) {
    setIsCreate(false);
    await loadCompanyData(e.row.data.id)
      .then(() => {
        setShowCompanyEditForm(true);
      });
    editFormTitle.current = "Editing: " + e.row.data.displayName;
    addCategoriesFormTitle.current = "Add Categories to Subtrade: " + e.row.data.displayName;
  }

  async function loadCompanyData(id) {
    var companyData = await companiesApiService.getCompanyDetails(id);
    setEditingCompanyViewData(companyData);
    setShowEstimateCategories(companyData.subtrade);
  }

  async function createCompanyButtonClick() {
    await loadCompanyData(0)
      .then(() => {
        editFormTitle.current = "New Company";
        addCategoriesFormTitle.current = "Add Categories to New Company"
        setIsCreate(true);
        setShowCompanyEditForm(true);
      });
  }

  function resetCompanyDto() {
    editingCompanyDtoData.current = {
      estimateCategories: {
        toAdd: [],
        toRemove: []
      }
    }
  }

  const createCompanyButtonOptions = {
    text: "+",
    onClick() {
      createCompanyButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Companies</h2>
      { renderCompanyEditForm() }
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={companiesDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        ref={companiesDataGridRef}
        remoteOperations={true}
        showColumnLines={true}
        id={companiesDataGridName}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnMinWidth={100}
        height={() => calculateGridHeight(companiesDataGridName) }
      >
        <Toolbar>
          <Item
            name="groupPanel"
            location="before"
          />
          <Item
            location="after"
            widget="dxButton"
            options={createCompanyButtonOptions}
          />
        </Toolbar>
        <Paging defaultPageSize={50} />
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <FilterRow visible={true} />
        <Editing
          mode="cell"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={true}
        >
        </Editing>
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            icon="edit"
            onClick={(e) => companyGridOnEditingStart(e)}
          />
        </Column>
        <Column
          dataField="displayName"
          sortIndex={0}
          sortOrder="asc"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="sageName"
          allowEditing={false}
        />
        <Column
          dataField="subtrade"
          width="150px"
        />
        <Column
          dataField="supplier"
          width="150px"
        />
        <Column
          name="deleteButton"
          type="buttons"
        >
          <Button
            icon="trash"
            name="delete"
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}
