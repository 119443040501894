export const navigation = [
  {
    text: 'Business Development',
    icon: 'doc',
    items: [
      {
        text: 'Companies',
        path: '/companies',
      },
      {
        text: 'Opportunities',
        path: '/opportunities',
      },
    ]
  },
  {
    text: "Estimating",
    icon: "datafield",
    items: [
      {
        text: 'Estimates',
        path: '/estimates'
      },
      {
        text: 'Bid Log',
        path: '/bid-log'
      },
      {
        text: 'Rate Sheet',
        path: 'rate-sheet'
      },
      {
        text: 'Estimate Categories',
        path: '/estimate-categories'
      },
      {
        text: 'Estimate Templates',
        path: '/estimate-templates'
      }
    ]
  },
  {
    text: 'Accounting',
    icon: 'money',
    items: [
      {
        text: 'Accounts Payable',
        path: '/accounts-payable',
      },
      {
        text: 'Cashflow',
        path: '/cashflow',
      },
      {
        text: 'Payments',
        path: '/payments',
      },
      
    ]
  },
  {
    text: 'Shareholders',
    icon: 'share',
    items: [
      {
        text: 'Profit Analysis',
        path: '/profit-analysis',
      }
    ]
  }
  ];
