import React,
{
  useRef,
  useState,
}
  from "react";
import DataGrid, {
  Paging,
  Editing,
  Scrolling,
  Selection,
  FilterRow,
  Grouping,
  Toolbar,
  Item,
  Texts
} from "devextreme-react/data-grid";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';

//This component is used to manage a many-to-many relationship between two objects
//Use of this component requires that the hosting page has data structured in the following way:
//  * A ViewData object that has Related and Unrelated collections of the many to many object. (eg. EstimateTemplateDetailsViewModel.RelatedEstimateScopes and .UnrelatedEstimateScopes)
//  * a DTO object that has toAdd and toRemove collections (eg. EstimateTemplate)

export function ManyToManyGrid(props) {
  const {
    relatedGridDataSource,
    unrelatedGridDataSource,
    addButtonText,
    removeMultipleRelatedButtonText,
    removeMultipleMessage,
    confirmDeleteMessage,
    addUnrelatedPopupHeaderTitle,
    relatedObjectsToAddCollection, //This is the collection of related objects on the parent objects dto from the hosting page (eg, EstimateScopes.toAdd)
    relatedObjectsToRemoveCollection, //This is the collection of related objects on the parent objects dto from the hosting page (eg, EstimateScopes.toRemove)
    relatedGridColumns,
    unrelatedGridColumns,
    message
  } = props;

  const relatedGridRef = useRef(null);
  const unrelatedGridRef = useRef(null);
  const [showAddUnrelatedPopup, setShowAddUnrelatedPopup] = useState(false);

  const addUnrelatedContinueButtonOptions = {
    text: "Continue",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      addUnrelatedContinueClick();
    }
  }

  function addUnrelatedContinueClick() {
    var selectedRows = unrelatedGridRef.current.instance.getSelectedRowKeys();
    
    selectedRows.forEach((row) => {
      //Add the ids of the selected rows to the .toAdd collection so they can be persisted
      relatedObjectsToAddCollection.push(row.id);
      //Now remove the selected rows from the Unrelated collection and add them to the
      //related collection to that the grids show the correct rows
      var unrelatedIndex = unrelatedGridDataSource.indexOf(row);
      if (unrelatedIndex > -1) {
        var unrelatedObject = unrelatedGridDataSource.at(unrelatedIndex);
        relatedGridDataSource.push(unrelatedObject);
        unrelatedGridDataSource.splice(unrelatedIndex, 1);
      }
    });
    setShowAddUnrelatedPopup(false);
    relatedGridRef.current.instance.refresh();
  }

  const addUnrelatedCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      setShowAddUnrelatedPopup(false);
    }
  }

  function renderAddUnrelatedPopup() {
    if (showAddUnrelatedPopup) {
      return (
        <Popup
          visible={showAddUnrelatedPopup}
          showCloseButton={false}
          title={addUnrelatedPopupHeaderTitle}
          onHiding={() => {
            setShowAddUnrelatedPopup(false);
          }}
        >
          <div className="spacedDiv">{message}</div>
          <DataGrid
            dataSource={unrelatedGridDataSource}
            ref={unrelatedGridRef}
          >
            <Selection
              mode="multiple"
              showCheckBoxesMode="always"
            />
            <FilterRow visible={true} />
            <Grouping autoExpandAll={false} />
            <Scrolling
              mode='virtual'
              rowRenderingMode='virtual'
            />
            <Paging defaultPageSize={50} />
            {unrelatedGridColumns}
          </DataGrid>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={addUnrelatedContinueButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={addUnrelatedCancelButtonOptions}
          />
        </Popup>
      )
    }
  }

  const addUnrelatedButtonOptions = {
    text: addButtonText,
    type: "default",
    stylingMode: "outlined",
    onClick() {
      setShowAddUnrelatedPopup(true);
    }
  }

  const removeRelatedButtonOptions = {
    text: removeMultipleRelatedButtonText,
    type: "default",
    stylingMode: "outlined",
    onClick() {
      removeMultipleRelatedItemsButtonClick();
    }
  }

  function removeMultipleRelatedItemsButtonClick() {
    if (window.confirm(removeMultipleMessage)) {
      var selectedRows = relatedGridRef.current.instance.getSelectedRowKeys();
      removeRelatedObjects(selectedRows);
      relatedGridRef.current.instance.refresh();
    }
  }

  function removeRelatedObjects(rows) {
    rows.forEach((row) => {
      //Do we have to remove this row from the toAdd collection?
      var toAddIndex = relatedObjectsToAddCollection.indexOf(row.id);
      if (toAddIndex > -1) {
        //Since this row was in the to add collection, we remove it from the toAdd collection but
        //DON'T add it to the toRemove collection as there is nothing to remove at the domain level
        relatedObjectsToAddCollection.splice(toAddIndex, 1);
      }
      else {
        relatedObjectsToRemoveCollection.push(row.id);
      }
      //and we have to move the selected objects between the related and unrelated data sources
      var relatedIndex = relatedGridDataSource.indexOf(row);
      if (relatedIndex > -1) {
        var relatedObject = relatedGridDataSource.at(relatedIndex);
        unrelatedGridDataSource.push(relatedObject);
        relatedGridDataSource.splice(relatedIndex, 1);
      }
    });
  }

  function relatedGridOnRowRemoving(e) {
    var rowArray = [];
    rowArray.push(e.data);
    removeRelatedObjects(rowArray);
    e.cancel = true;
    relatedGridRef.current.instance.refresh();
  }

  return (
    <React.Fragment>
      {renderAddUnrelatedPopup()}
      <DataGrid
        dataSource={relatedGridDataSource}
        ref={relatedGridRef}
        onRowRemoving={relatedGridOnRowRemoving}
      >
        <Editing
          allowAdding={false}
          allowUpdating={false}
          allowDeleting={true}
        >
          <Texts
            confirmDeleteMessage={confirmDeleteMessage}
          />
        </Editing>
        <Toolbar>
          <Item
            location="before"
            widget="dxButton"
            options={addUnrelatedButtonOptions}
          />
          <Item
            location="after"
            widget="dxButton"
            options={removeRelatedButtonOptions}
          />
        </Toolbar>
        <FilterRow visible={true} />
        <Selection
          mode="multiple"
        />
        {relatedGridColumns}
      </DataGrid>
    </React.Fragment>
  );
}