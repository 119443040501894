import React from "react";
import { Routes, Route } from "react-router-dom";
import appInfo from "./app-info";
import { SideNavInnerToolbar as SideNavBarLayout } from "./layouts";
import {
  AccountsPayablePage,
  BidLogPage,
  CashflowPage,
  CompaniesPage,
  ContactsPage,
  EstimateCategoriesPage,
  EstimateTemplatesPage,
  EstimatesPage,
  HomePage,
  OpportunitiesPage,
  ProfitAnalysisPage,
  PaymentsPage,
  RateSheetPage
} from "./pages";
import {
  AccountsPayablePageService,
  CashflowPageService,
  CompaniesPageService,
  CompanyLookupService,
  ContactsPageService,
  EstimateCategoriesPageService,
  EstimateTemplatesPageService,
  EstimatesPageService,
  OpportunitiesPageService,
  PaymentsPageService,
  PaymentStatusLookupService,
  ProjectStatusLookupService,
  ProfitAnalysisPageService,
  RateSheetPageService
} from "./services";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { InteractionType } from "@azure/msal-browser";
import SignOutButton from './components/sign-out-button/sign-out-button';

export default function MainContent() {
  const authRequest = {
    ...loginRequest,
  };
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <SideNavBarLayout title={appInfo.title}>
        <SignOutButton />
        <Routes>
          <Route
            path="/accounts-payable"
            element={auth(
              <AccountsPayablePage accountsPayableApiService={AccountsPayablePageService} />,
            )}
          />
          <Route
            path="/cashflow"
            element={auth(
              <CashflowPage
                cashflowApiService={CashflowPageService}
                projectStatusLookupApiService={ProjectStatusLookupService}
              />,
            )}
          />
          <Route
            path="/companies"
            element={auth(
              <CompaniesPage companyApiService={CompaniesPageService} />,
            )}
          />
          <Route
            path="/estimates"
            element={auth(
              <EstimatesPage
                estimatesApiService={EstimatesPageService}
                projectStatusLookupApiService={ProjectStatusLookupService}
              />
            )}
          />
          <Route
            path="/estimate-templates"
            element={auth(
              <EstimateTemplatesPage
                estimateTemplatesApiService={EstimateTemplatesPageService}
              />
            )}
          />
          <Route
            path="/bid-log"
            element={auth(
              <BidLogPage />
            )}
          />
          <Route
            path="/rate-sheet"
            element={auth(
              <RateSheetPage
                rateSheetPageApiService={RateSheetPageService}
              />
            )}
          />
          <Route
            path="/estimate-categories"
            element={auth(
              <EstimateCategoriesPage
                estimateCategoriesApiService={EstimateCategoriesPageService}
              />
            )}
          />
          <Route
            path="/payments"
            element={auth(
              <PaymentsPage
                paymentsApiService={PaymentsPageService}
                paymentStatusLookupApiService={PaymentStatusLookupService}
              />,
            )}
          />
          <Route
            path="/opportunities"
            element={auth(
              <OpportunitiesPage
                opportunitiesApiService={OpportunitiesPageService}
                projectStatusLookupApiService={ProjectStatusLookupService}
                companyApiService={CompaniesPageService}
                companyLookupService={CompanyLookupService}
              />,
            )}
          />
          <Route
            path="/profit-analysis"
            element={
              auth(
                <ProfitAnalysisPage
                  profitAnalysisApiService={ProfitAnalysisPageService}
                />
              )}
          />
          <Route
            path="/contacts"
            element={
              auth(
                <ContactsPage
                  contactsApiService={ContactsPageService}
                />
              )}
          />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </SideNavBarLayout>
    </MsalAuthenticationTemplate>
  );
}

const auth = (element) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {element}
    </MsalAuthenticationTemplate>
  );
};
