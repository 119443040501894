import
  React,
  {
    useState,
  }
  from 'react';
import styles from "./collapsible-panel.module.scss";

export default function CollapsiblePanel(props) {
  const [open, setOpen] = useState(props.expanded ?? false);

  function toggle() {
    setOpen(!open);
  }

  const headerIconClass = open ? "dx-icon-chevronup" : "dx-icon-chevronleft";
  const contentParentClass = open ? `${styles["content-parent"]} ${styles["expanded"]}` : `${styles["content-parent"]}`;

  return (
    <div className={styles["collapsible-panel"]} >
      <div 
        className={styles["header"] }
        onClick={toggle}
      >
        <div className={styles["headerTitle"]}>{props.title}</div>
        <div className={styles["headerInfo"]}>{props.info}</div>
        <div className={styles["headerIcon"]}>
          <i className={headerIconClass}></i>
        </div>
        
      </div>
      <div
        className={contentParentClass}
      >
        <div className={styles["content"]}>
          {props.children}
        </div>
      </div>
    </div>
  )
}