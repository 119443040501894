import React,
{
  useRef,
  useState,
}
  from "react";
import DataGrid,
{
  Column,
  Paging,
  Button,
  Editing,
  Scrolling,
  //Selection,
  //Grouping,
  Toolbar,
} from "devextreme-react/data-grid";
import TabPanel, {
  Item
} from "devextreme-react/tab-panel";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import Form, {
  RequiredRule,
} from "devextreme-react/form";
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";
import generalStyles from '../../themes/general.module.scss';
import { ManyToManyGrid } from '../../components/many-to-many-grid/many-to-many-grid';

export default function EstimateTemplates(props) {
  const estimateTemplatesApiService = new props.estimateTemplatesApiService();
  const estimateTemplatesDataSource = estimateTemplatesApiService.getEstimateTemplatesDataSource();

  const estimateTemplatesDataGridName = "EstimateTemplatesDataGrid";
  const estimateTemplatesDataGridRef = useRef(null);

  const [showEstimateTemplateEditForm, setShowEstimateTemplateEditForm] = useState(false);
  const [editingEstimateTemplateViewData, setEditingEstimateTemplateViewData] = useState();
  const [isCreate, setIsCreate] = useState(false);

  const editingEstimateTemplateDtoData = useRef(
    {
      estimateScopes: {
        toAdd: [],
        toRemove: [],
      }
    }
  );
  const editFormTitle = useRef('');
  const editFormRef = useRef();
  const editingTemplateName = useRef('');
  const addScopesFormTitle = useRef('');

  function cleanupAndCloseEditForm() {
    setIsCreate(false);
    setShowEstimateTemplateEditForm(false);
    setEditingEstimateTemplateViewData();
    resetEstimateTemplateDto();
  }

  function resetEstimateTemplateDto() {
    editingEstimateTemplateDtoData.current = {
      estimateScopes: {
        toAdd: [],
        toRemove: [],
      }
    }
  }

  function estimateTemplateEditFormFieldDataChanged(e) {
    if (e.dataField === "name") {
      var dtoHasName = editingEstimateTemplateDtoData.current.hasOwnProperty("name");
      if (!dtoHasName) {
        Object.defineProperty(editingEstimateTemplateDtoData.current, "name", { value: "", writable: true, enumerable: true });
      }
      editingEstimateTemplateDtoData.current.name = e.value;
    }
  }

  const estimateTemplateEditFormSaveButtonOptions = {
    text: "Save",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      estimateTemplateEditFormSaveButtonOnClickAsync();
    }
  }

  async function estimateTemplateEditFormSaveButtonOnClickAsync() {
    var formInstance = editFormRef.current.instance;
    var validationResult = formInstance.validate();
    if (validationResult.isValid) {
      await saveEstimateTemplateDataAsync()
        .then(() => {
          cleanupAndCloseEditForm();
        });
    }
  }

  async function saveEstimateTemplateDataAsync() {
    if (isCreate) {
      await estimateTemplatesApiService.createEstimateTemplate(editingEstimateTemplateDtoData.current);
    }
    else {
      await estimateTemplatesApiService.updateEstimateTemplate(editingEstimateTemplateViewData.id, editingEstimateTemplateDtoData.current);
    }
  }

  const estimateTemplateEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      cleanupAndCloseEditForm();
    }
  }

  const manyToManyRelatedGridColumns = [
    <Column
      dataField="divisionName"
      groupIndex={0}
      sortIndex={0}
      allowEditing={false}
    />,
    <Column
      dataField="name"
      sortIndex={1}
      sortOrder="asc"
    />,
  ];

  const manyToManyUnrelatedGridColumns = [
    <Column
      dataField="divisionName"
      groupIndex={0}
      sortIndex={0}
      allowEditing={false}
    />,
    <Column
      dataField="name"
      sortIndex={1}
      sortOrder="asc"
    />,
  ];

  function renderEstimateTemplateEditForm() {
    if (showEstimateTemplateEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showEstimateTemplateEditForm}
          showCloseButton={true}
          onHiding={() => {
            cleanupAndCloseEditForm();
          }}
          title={editFormTitle.current}
        >
          <TabPanel>
            <Item
              title="Properties"
            >
              <Form
                id="editingEstimateDivisionForm"
                formData={editingEstimateTemplateViewData}
                onFieldDataChanged={estimateTemplateEditFormFieldDataChanged}
                ref={editFormRef}
              >
                <Item
                  dataField="name"
                >
                  <RequiredRule />
                </Item>
              </Form>
              <div className={generalStyles.gridHeader}>Estimate Scopes in this Template</div>
              <ManyToManyGrid
                relatedGridDataSource={editingEstimateTemplateViewData.relatedEstimateScopes.$values}
                unrelatedGridDataSource={editingEstimateTemplateViewData.unrelatedEstimateScopes.$values}
                addButtonText="Add Scopes to Template..."
                removeMultipleRelatedButtonText="Remove Selected Scopes..."
                removeMultipleMessage="Are you sure you want to remove the selected Scopes from this Template?"
                confirmDeleteMessage="Are you sure you want to remove this Scope from the Template?"
                addUnrelatedPopupHeaderTitle={addScopesFormTitle.current}
                relatedObjectsToAddCollection={editingEstimateTemplateDtoData.current.estimateScopes.toAdd}
                relatedObjectsToRemoveCollection={editingEstimateTemplateDtoData.current.estimateScopes.toRemove}
                relatedGridColumns={manyToManyRelatedGridColumns}
                unrelatedGridColumns={manyToManyUnrelatedGridColumns}
              />
            </Item>
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateTemplateEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateTemplateEditFormCancelButtonOptions}
          />
        </Popup>
      );
    }
  }

  async function estimateTemplateGridOnEditingStart(e) {
    setIsCreate(false);
    await loadEstimateTemplateData(e.row.data.id)
      .then(() => {
        setShowEstimateTemplateEditForm(true);
      });
    editFormTitle.current = "Editing: " + e.row.data.name;
    addScopesFormTitle.current = "Add Scopes to Template: " + e.row.data.name;
    editingTemplateName.current = e.row.data.name;
  }

  async function loadEstimateTemplateData(id) {
    var estimateTemplateData = await estimateTemplatesApiService.getEstimateTemplateDetails(id);
    setEditingEstimateTemplateViewData(estimateTemplateData);
  }

  async function createEstimateTemplateButtonClick() {
    await loadEstimateTemplateData(0)
      .then(() => {
        editFormTitle.current = "New Estimate Template";
        editingTemplateName.current = "New Template";
        addScopesFormTitle.current = "Add Scopes to New Template"
        setIsCreate(true);
        setShowEstimateTemplateEditForm(true);
      });
  }

  const createEstimateTemplateButtonOptions = {
    text: '+',
    onClick() {
      createEstimateTemplateButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Estimate Templates</h2>
      {renderEstimateTemplateEditForm()}
      <DataGrid
        dataSource={estimateTemplatesDataSource}
        ref={estimateTemplatesDataGridRef}
        id={estimateTemplatesDataGridName}
        height={() => calculateGridHeight(estimateTemplatesDataGridName) }
      >
        <Paging
          defaultPageSize={50}
        />
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <Editing
          allowAdding={false}
          allowUpdating={false}
          allowDeleting={true}
        />
        <Toolbar>
          <Item
            location="after"
            widget="dxButton"
            options={createEstimateTemplateButtonOptions}
          />
        </Toolbar>
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            icon="edit"
            onClick={(e) => estimateTemplateGridOnEditingStart(e) }
          />
        </Column>
        <Column
          dataField="name"
          sortIndex={0}
          sortOrder="asc"
        />
        <Column
          name="deleteButton"
          type="buttons"
        >
          <Button
            icon="trash"
            name="delete"
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}