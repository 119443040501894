import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";
import { fetchApi } from "../../utils/fetch-api";

const {
  squireApiConfig: { url },
} = window.env;

export default class EstimateCategoriesApiService {
  getEstimateCategoriesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimate-categories/`,
      deleteUrl: `${url}/v1/estimate-categories/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getEstimateCategoryDetails(estimateCategoryId) {
    const fetchUrl = `${url}/v1/estimate-categories/${estimateCategoryId}`;
    return await fetchApi(fetchUrl, "GET");
  }

  async createEstimateCategory(values) {
    const fetchUrl = `${url}/v1/estimate-categories/`;
    const formBody = new URLSearchParams({
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "POST", formBody);
  }

  async updateEstimateCategory(estimateCategoryId, values) {
    const fetchUrl = `${url}/v1/estimate-categories/`;
    const formBody = new URLSearchParams({
      estimateCategoryId: estimateCategoryId,
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "PUT", formBody);
  }
}