import { createStore } from "devextreme-aspnet-data-nojquery";
import { setupRequestAsync } from "../../utils/auth";
import { fetchApi } from "../../utils/fetch-api";

const {
  squireApiConfig: { url },
} = window.env;

export default class EstimateTemplateApiService {
  getEstimateTemplatesDataSource() {
    return createStore({
      key: "id",
      loadUrl: `${url}/v1/estimate-templates/`,
      deleteUrl: `${url}/v1/estimate-templates/`,
      onBeforeSend: async (method, ajaxOptions) =>
        await setupRequestAsync(method, ajaxOptions),
    });
  }

  async getEstimateTemplateDetails(estimateTemplateId) {
    const fetchUrl = `${url}/v1/estimate-templates/${estimateTemplateId}`;
    return await fetchApi(fetchUrl, "GET");
  }

  async createEstimateTemplate(values) {
    const fetchUrl = `${url}/v1/estimate-templates/`;
    const formBody = new URLSearchParams({
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "POST", formBody);
  }

  async updateEstimateTemplate(estimateTemplateId, values) {
    const fetchUrl = `${url}/v1/estimate-templates/`;
    const formBody = new URLSearchParams({
      estimateTemplateId: estimateTemplateId,
      values: JSON.stringify(values),
    });
    await fetchApi(fetchUrl, "PUT", formBody);
  }
}