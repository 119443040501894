import React,
{
  useRef,
  useState,
}
  from "react";
import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  Button,
  Editing,
  Scrolling,
  Toolbar,
  Grouping,
  GroupPanel,
  MasterDetail,
} from "devextreme-react/data-grid";
import TabPanel, {
  Item
} from "devextreme-react/tab-panel";
import Form, {
  Label,
  SimpleItem,
  RequiredRule,
  NumericRule,
} from "devextreme-react/form";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";
import { currencyFormat } from "../../utils/formatting";
import { ManyToManyGrid } from '../../components/many-to-many-grid/many-to-many-grid';
import styles from "./rate-sheet.module.scss";

export default function RateSheet(props) {
  const rateSheetApiService = new props.rateSheetPageApiService();
  const estimateScopesDataSource = rateSheetApiService.getEstimateScopesDataSource();
  const estimateCategoriesDataSource = rateSheetApiService.getEstimateCategoriesDataSource();

  const estimateScopesDataGridName = "EstimateScopesDataGrid";
  const estimateScopesDataGridRef = useRef(null);
  const rateCalculationsDataGridName = "RateCalculationsGrid";
  const rateCalculationsDataGridRef = useRef(null);
  const bandedRateCalculationsDataGridName = "BandedRateCalculationsGrid";
  const bandedRateCalculationsDataGridRef = useRef(null);
  const formRef = useRef(null);
  const bandDetailGrids = useRef([]);

  const [showEstimateScopesEditForm, setShowEstimateScopesEditForm] = useState();
  const [estimateScopeViewData, setEstimateScopeViewData] = useState();
  const [isCreate, setIsCreate] = useState(false);
  const editingEstimateScopeDto = useRef(
    {
      rateCalculations: {
        toCreate: [],
        toUpdate: {},
        toDelete: []
      },
      bandedRateCalculations: {
        toCreate: [],
        toUpdate: {},
        toDelete: []
      },
      estimateTemplates: {
        toAdd: [],
        toRemove: [],
      }
    }
  );
  const editFormTitle = useRef('');
  const addTemplatesFormTitle = useRef('');


  const estimateCategoryEditorOptions = {
    dataSource: estimateCategoriesDataSource,
    searchEnabled: true,
    displayExpr: "name",
    valueExpr: "id",
  }

  const estimateScopesEditFormSaveButtonOptions = {
    text: "Save",
    stylingMode: "outlined",
    type: "default",
    onClick() {
      //We need to set a bit of a delay on the button click so that the
      //grids in cell edit mode can update the dto before we persist it
      //I get this is hacky, but I just wanted to get this working before
      //making this interaction better.
      setTimeout(estimateScopeEditFormSaveButtonClick, 500);
      //estimateScopeEditFormSaveButtonClick();
    }
  }

  async function estimateScopeEditFormSaveButtonClick() {
    var formInstance = formRef.current.instance;
    var validationResult = formInstance.validate();
    if (validationResult.isValid) {
        await saveEstimateScopeData()
          .then(() => {
            cleanupAndCloseEditForm();
          });
      }
    }

  function cleanupAndCloseEditForm() {
    setIsCreate(false);
    setShowEstimateScopesEditForm(false);
    setEstimateScopeViewData();
    resetEstimateScopeDto();
  }

  async function saveEstimateScopeData() {
    getBandedRateDetailGridData();
    stringifyToUpdateCollections();
    if (isCreate) {
      await rateSheetApiService.createEstimateScope(editingEstimateScopeDto.current);
    }
    else {
      await rateSheetApiService.updateEstimateScope(estimateScopeViewData.id, editingEstimateScopeDto.current);
    }
  }

  function getBandedRateDetailGridData() {
    //After clicking save one thing we still need to get is the data in the
    //detail grids for each newly created banded calculation
    editingEstimateScopeDto.current.bandedRateCalculations.toCreate.forEach((calc) => addBandDataToBandedCalculationToCreate(calc));

    //and for each toUpdate banded calculation in which the bands where updated.
    for (const [key, value] of Object.entries(editingEstimateScopeDto.current.bandedRateCalculations.toUpdate)) {
      var hasBandsProperty = value.hasOwnProperty("bands");
      if (hasBandsProperty) {
        var visibleRows = bandDetailGrids[key].instance.getVisibleRows();
        visibleRows.forEach((row) => getDataFromRow(row, value.bands))
      }
    }
  }

  function stringifyToUpdateCollections() {
    for (const [key, value] of Object.entries(editingEstimateScopeDto.current.bandedRateCalculations.toUpdate)) {
      editingEstimateScopeDto.current.bandedRateCalculations.toUpdate[key] = JSON.stringify(value);
    }
    for (const [key, value] of Object.entries(editingEstimateScopeDto.current.rateCalculations.toUpdate)) {
      editingEstimateScopeDto.current.rateCalculations.toUpdate[key] = JSON.stringify(value);
    }
  }

  function addBandDataToBandedCalculationToCreate(calc) {
    //Define a 'bands' property on the .toCreate calculation
    var hasBandsProperty = calc.hasOwnProperty("bands");
    if (!hasBandsProperty) {
      Object.defineProperty(calc, "bands", { value: [], writable: true, enumerable: true });
    }
    //Then get the data from the bands grid and put it in the 'bands' collection.
    var visibleRows = bandDetailGrids[calc.key].instance.getVisibleRows();
    visibleRows.forEach((row) => getDataFromRow(row, calc.bands));
  }

  function getDataFromRow(row, bandsCollection) {
    if (row.rowType === 'data') {
      bandsCollection.push(row.data);
    }
  }

  const estimateScopesEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      cleanupAndCloseEditForm();
    }
  }

  function estimateScopeEditFormFieldDataChanged(e) {
    if (e.dataField === "name") {
      var dtoHasName = editingEstimateScopeDto.current.hasOwnProperty("name");
      if (!dtoHasName) {
        Object.defineProperty(editingEstimateScopeDto.current, "name", { value: "", writable: true, enumerable: true });
      }
      editingEstimateScopeDto.current.name = e.value;
    }
    if (e.dataField === "estimateCategoryId") {
      var dtoHasEstimateCategoryId = editingEstimateScopeDto.current.hasOwnProperty("estimateCategoryId");
      if (!dtoHasEstimateCategoryId) {
        Object.defineProperty(editingEstimateScopeDto.current, "estimateCategoryId", { value: "", writable: true, enumerable: true });
      }
      editingEstimateScopeDto.current.estimateCategoryId = e.value;
    }
  }

  function rateCalculationsGridOnRowUpdating(e) {
    //We may be updating a row, but are we updating one that was newly created and so hasn't be persisted and so can't be updated?
    //The way we can check this is by looking for the existance of an Id property.
    var id = e.key.id;
    if (typeof id === 'undefined') {
      //This is an update to a rate that was newly created, so lets find it in the .toCreate collection by it's key
      var existingRateToCreate = editingEstimateScopeDto.current.rateCalculations.toCreate.find(c => c.key === e.key.__KEY__);
      var existingIndex = editingEstimateScopeDto.current.rateCalculations.toCreate.findIndex(c => c.key === e.key.__KEY__);
      var updatedRate = {};
      if (typeof existingRateToCreate !== 'undefined') {
        //Now update the properties for the newData
        updatedRate = {
          ...existingRateToCreate,
          ...e.newData
        }
        //And now replace this rate in the toCreate collection.
        editingEstimateScopeDto.current.rateCalculations.toCreate.splice(existingIndex, 1);
        editingEstimateScopeDto.current.rateCalculations.toCreate.push(updatedRate);
      }
    }
    else {
      //Is this calculation already in our toUpdate collection?
      const toUpdate = editingEstimateScopeDto.current.rateCalculations.toUpdate;
      let data = {};
      if (toUpdate.hasOwnProperty(e.key.id)) {
        data = {
          ...toUpdate[e.key.id],
          ...e.newData
        }
      }
      else {
        data = { ...e.newData }
      }

      toUpdate[e.key.id] = data;
    }
  }

  const createRateCalculationButtonOptions = {
    text: 'Add a Rate...',
    onClick() {
      createRateCalculationButtonClick();
    }
  }

  function createRateCalculationButtonClick() {
    rateCalculationsDataGridRef.current.instance.addRow();
  }

  function rateCalculationGridOnRowInserting(e) {
    var newCalculation = {
      name: e.data.name,
      decimalPlaces: e.data.decimalPlaces,
      unitsOfMeasurement: e.data.unitsOfMeasurement,
      rate: e.data.rate,
      //We need to set this property so that we can update this newly created rate (see the code in rateCalculationsGridOnRowUpdating())
      key: e.data.__KEY__
    };
    editingEstimateScopeDto.current.rateCalculations.toCreate.push(newCalculation);
  }

  function rateCalculationOnRowRemoving(e) {
    //Is the user attempting to delete a row they also created in this session?
    var createdCalcIndex = editingEstimateScopeDto.current.rateCalculations.toCreate.findIndex(calc => calc.name === e.key.name);
    if (createdCalcIndex > -1) {
      //Yes it is, just remove it from the toCreate collection
      editingEstimateScopeDto.current.rateCalculations.toCreate.splice(createdCalcIndex, 1);
    }
    else {
      //No, this calculation existed already, add it to the 'ToDelete' collection
      editingEstimateScopeDto.current.rateCalculations.toDelete.push(e.key.id);
    }
  }

  function bandedCalculationsGridOnRowInserting(e) {
    var newBandedCalculation = {
      name: e.data.name,
      decimalPlaces: e.data.decimalPlaces,
      unitsOfMeasurement: e.data.unitsOfMeasurement,
      key: e.data.__KEY__,
    }
    editingEstimateScopeDto.current.bandedRateCalculations.toCreate.push(newBandedCalculation);
  }

  function bandedCalculationsGridOnRowUpdating(e) {
    //We may be updating a row, but are we updating one that was newly created and so hasn't be persisted and so can't be updated?
    //The way we can check this is by looking for the existance of an Id property.
    var id = e.key.id;
    if (typeof id === 'undefined') {
      //This is an update to a rate that was newly created, so lets find it in the .toCreate collection by it's key
      var existingRateToCreate = editingEstimateScopeDto.current.bandedRateCalculations.toCreate.find(c => c.key === e.key.__KEY__);
      var updatedRate = {};
      if (typeof existingRateToCreate !== 'undefined') {
        //Now update the properties for the newData
        updatedRate = {
          ...existingRateToCreate,
          ...e.newData
        }
        //And now replace this rate in the toCreate collection.
        editingEstimateScopeDto.current.bandedRateCalculations.toCreate.splice(existingRateToCreate);
        editingEstimateScopeDto.current.bandedRateCalculations.toCreate.push(updatedRate);
      }
    }
    else {
      //Is this calculation already in our toUpdate collection?
      const toUpdate = editingEstimateScopeDto.current.bandedRateCalculations.toUpdate;
      let data = {};
      if (toUpdate.hasOwnProperty(e.key.id)) {
        data = {
          ...toUpdate[e.key.id],
          ...e.newData
        }
      }
      else {
        data = { ...e.newData }
      }

      toUpdate[e.key.id] = data;
    }
  }

  function bandedCalculationsGridOnRowRemoving(e) {
    //Is the user attempting to delete a row they also created in this session?
    var createdCalcIndex = editingEstimateScopeDto.current.bandedRateCalculations.toCreate.findIndex(calc => calc.name === e.key.name);
    if (createdCalcIndex > -1) {
      //Yes it is, just remove it from the toCreate collection
      editingEstimateScopeDto.current.bandedRateCalculations.toCreate.splice(createdCalcIndex, 1);
    }
    else {
      //No, this calculation existed already, add it to the 'ToDelete' collection
      editingEstimateScopeDto.current.bandedRateCalculations.toDelete.push(e.key.id);
    }
  }

  const createBandedCalculationButtonOptions = {
    text: 'Add a Banded Rate...',
    onClick() {
      createBandedCalculationButtonClick();
    }
  }

  const createBandButtonOptions = {
    text: 'Add a band...'
  }

  function createBandedCalculationButtonClick() {
    bandedRateCalculationsDataGridRef.current.instance.addRow();
  }

  function bandDetailGridOnRowUpdating(_e, calcData) {
    bandDetailGridDataChanged(calcData);
  }

  function bandDetailsGridOnRowInserting(_e, calcData) {
    bandDetailGridDataChanged(calcData);
  }

  function bandDetailsGridOnRowRemoving(_e, calcData) {
    bandDetailGridDataChanged(calcData);
  }

  function bandDetailGridDataChanged(calcData) {
    //A band row has been updated in some way.  We need to determine if:
    //- is the related calculation a new one or an existing one?
    //- if it is an existing one, is it already in the bandedRateCalculations.toUpdate collection?
    //  - if so, add a 'band' property to it so we will know to grab the bands on save
    //  - if not, add a 'band' property to it AND add it to the .toUpdate collection

    //To check if it is an update, see if the calcData passed to this function has an id property
    var calcId;
    if (typeof calcData.id !== 'undefined') {
      //Yes it does, is this calc already in the .toUpdate collection?
      calcId = calcData.id;
      const toUpdateCollection = editingEstimateScopeDto.current.bandedRateCalculations.toUpdate;
      var calcToUpdate;

      if (toUpdateCollection.hasOwnProperty(calcId)) {
        //Yes it is already in the collection
        calcToUpdate = toUpdateCollection[calcId];
        //Does it already have a bands property?
        var hasBandsProperty = calcToUpdate.hasOwnProperty("bands");
        if (!hasBandsProperty) {
          //No? then add one.
          Object.defineProperty(calcToUpdate, "bands", { value: [], writable: true, enumerable: true });
        }
      }
      else {
        //No, it isn't in the 'toUpdate' collection, so add it, along with the 'bands' collection.
        calcToUpdate = {};
        Object.defineProperty(calcToUpdate, "bands", { value: [], writable: true, enumerable: true });
        toUpdateCollection[calcData.id] = calcToUpdate;
      }
    }
    //That is all we need to do, in 'saveEstimateScopeData()' we look at all the
    //bandedRateCalculation.toCreate and .toUpdate to see if they have a 'bands' property
    //then get the data from the detail grid.
  }



  function setBandGridRef(data, grid) {
    //To set the grid ref we need to know if this is grid for a new banded calculation
    //or for an existing banded calculation
    //For some reason devextreme calls this multiple times for each grid, sometimes the grid is null, so make sure it is not.
    if (typeof grid !== 'undefined' && grid !== null) {
      if (typeof data.id !== 'undefined') {
        //If there is an id in the data, that means this is an existing calculation
        bandDetailGrids[data.id] = grid;
      }
      else {
        //This is a new banded calculation, set the grid ref to use the key property
        bandDetailGrids[data.__KEY__] = grid;
      }
    }
  }

  function bandedRateDetailComponent(props) {
    //we have to make sure that there are values to bind to this grid
    var data;
    if (typeof props.data.data.bands !== 'undefined') {
      data = props.data.data.bands.$values;
    }
    else {
      data = [];
    }
    const minCaption = "Min (" + props.data.data.unitsOfMeasurement + ")";
    const maxCaption = "Max (" + props.data.data.unitsOfMeasurement + ")";
    return (
      <React.Fragment>
        <DataGrid
          dataSource={data}
          ref={grid => setBandGridRef(props.data.data, grid)}
          onRowUpdating={(e) => bandDetailGridOnRowUpdating(e, props.data.data)}
          onRowInserting={(e) => bandDetailsGridOnRowInserting(e, props.data.data)}
          onRowRemoving={(e) => bandDetailsGridOnRowRemoving(e, props.data.data)}
          width="50vw"
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
            selectTextOnEditStart={true}
          />
          <Toolbar>
            <Item
              name="addRowButton"
              widget="dxButton"
              options={createBandButtonOptions}
              showText="always"
            />
          </Toolbar>
          <Column
            dataField="min"
            sortIndex={0}
            sortOrder="asc"
            caption={minCaption}
          >
            <RequiredRule />
            <NumericRule />
          </Column>
          <Column 
            dataField="max"
            caption={maxCaption}
          >
            <RequiredRule />
            <NumericRule />
          </Column>
          <Column
            dataField="rate"
            format={currencyFormat}
          >
            <RequiredRule />
            <NumericRule />
          </Column>
        </DataGrid>
      </React.Fragment>
    );
  }

  const manyToManyGridColumns = [
    <Column
      dataField="name"
      sortIndex={0}
      sortOrder="asc"
    />
  ];

  function renderEstimateScopeEditForm() {
    if (showEstimateScopesEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showEstimateScopesEditForm}
          showCloseButton={true}
          title={editFormTitle.current}
          onHiding={() => {
            cleanupAndCloseEditForm();
          }}
        >
          <TabPanel>
            <Item 
              title="Properties"
            >
              <Form
                id="editingEstimateScopeForm"
                formData={estimateScopeViewData}
                onFieldDataChanged={estimateScopeEditFormFieldDataChanged}
                validationGroup="estimateScopeData"
                ref={formRef}
              >
                <SimpleItem dataField="name">
                  <RequiredRule
                    message="Name is required"
                  />
                </SimpleItem>
                <SimpleItem
                  dataField="estimateCategoryId"
                  editorType="dxSelectBox"
                  editorOptions={estimateCategoryEditorOptions}
                >
                  <RequiredRule
                    message="Estimate Category is required"
                  />
                  <Label 
                    text="Estimate Category"
                  />
                </SimpleItem>
              </Form>
              <div className={styles.calculationGridHeader}>Rate Calculations</div>
              <DataGrid
                dataSource={estimateScopeViewData.rateCalculations.$values}
                ref={rateCalculationsDataGridRef}
                id={rateCalculationsDataGridName}
                onRowUpdating={rateCalculationsGridOnRowUpdating}
                onRowInserting={rateCalculationGridOnRowInserting}
                onRowRemoving={rateCalculationOnRowRemoving}
                width="75vw"
              >
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowDeleting={true}
                  allowCreating={true}
                  selectTextOnEditStart={true}
                />
                <Toolbar>
                  <Item
                    location="after"
                    widget="dxButton"
                    options={createRateCalculationButtonOptions}
                  />
                </Toolbar>
                <Column
                  dataField="name"
                  sortIndex={0}
                  sortOrder="asc"
                >
                  <RequiredRule />
                </Column>
                <Column
                  dataField="unitsOfMeasurement"
                >
                  <RequiredRule />
                </Column>
                <Column
                  dataField="decimalPlaces"
                >
                  <RequiredRule />
                  <NumericRule />
                </Column>
                <Column
                  dataField="rate"
                  format={currencyFormat}
                >
                  <RequiredRule />
                  <NumericRule />
                </Column>
              </DataGrid>
              <div className={styles.calculationGridHeader}>Banded Rate Calculations</div>
              <DataGrid
                dataSource={estimateScopeViewData.bandedRateCalculations.$values}
                ref={bandedRateCalculationsDataGridRef}
                id={bandedRateCalculationsDataGridName}
                onRowInserting={bandedCalculationsGridOnRowInserting}
                onRowUpdating={bandedCalculationsGridOnRowUpdating}
                onRowRemoving={bandedCalculationsGridOnRowRemoving}
                repaintChangesOnly={true}
                width="75vw"
              >
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowDeleting={true}
                  allowCreating={true}
                />
                <Toolbar>
                  <Item
                    location="after"
                    widget="dxButton"
                    options={createBandedCalculationButtonOptions}
                  />
                </Toolbar>
                <Column
                  dataField="name"
                  sortIndex={0}
                  sortOrder="asc"
                >
                  <RequiredRule />
                </Column>
                <Column
                  dataField="unitsOfMeasurement"
                >
                  <RequiredRule />
                </Column>
                <Column
                  dataField="decimalPlaces"
                >
                  <RequiredRule />
                  <NumericRule />
                </Column>
                <MasterDetail
                  enabled={true}
                  component={bandedRateDetailComponent}
                  autoExpandAll={true}
                />
              </DataGrid>
            </Item>
            <Item
              title="Templates"
            >
              <div className="spacedDiv">The following grid lists all of the Estimate Templates which include this scope.</div>
              <ManyToManyGrid
                relatedGridDataSource={estimateScopeViewData.relatedEstimateTemplates.$values}
                unrelatedGridDataSource={estimateScopeViewData.unrelatedEstimateTemplates.$values}
                addButtonText="Add this Scope to Templates..."
                removeMultipleRelatedButtonText="Remove Scope from selected Templates..."
                removeMultipleMessage="Are you sure you want to remove this scope from the selected Templates?"
                confirmDeleteMessage="Are you sure you want to remove the Scope from this Template?"
                addUnrelatedPopupHeaderTitle={addTemplatesFormTitle.current}
                relatedObjectsToAddCollection={editingEstimateScopeDto.current.estimateTemplates.toAdd}
                relatedObjectsToRemoveCollection={editingEstimateScopeDto.current.estimateTemplates.toRemove}
                relatedGridColumns={manyToManyGridColumns}
                unrelatedGridColumns={manyToManyGridColumns}
              />
            </Item>
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateScopesEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateScopesEditFormCancelButtonOptions}
          />
        </Popup>
      )
    }
  }

  async function estimateScopesGridOnEditingStart(e) {
    setIsCreate(false);
    await loadEstimateScopeData(e.row.data.id)
      .then(() => {
        setShowEstimateScopesEditForm(true);
      });
    editFormTitle.current = "Editing: " + e.row.data.name;
    addTemplatesFormTitle.current = "Add Scope " + e.row.data.name + " to Templates";
  }

  async function loadEstimateScopeData(id) {
    var estimateScopeData = await rateSheetApiService.getEstimateScopeDetails(id);
    setEstimateScopeViewData(estimateScopeData);
  }

  async function createEstimateScopeButtonClick() {
    await loadEstimateScopeData(0)
      .then(() => {
        editFormTitle.current = "New Estimate Scope";
        addTemplatesFormTitle.current = "Add New Scope to Templates";
        setIsCreate(true);
        setShowEstimateScopesEditForm(true);
      })
  }

  function resetEstimateScopeDto() {
    editingEstimateScopeDto.current = {
      rateCalculations: {
        toCreate: [],
        toUpdate: {},
        toDelete: []
      },
      bandedRateCalculations: {
        toCreate: [],
        toUpdate: {},
        toDelete: []
      },
      estimateTemplates: {
        toAdd: [],
        toRemove: [],
      }
    };
  }

  const createEstimateScopeButtonOptions = {
    text: "+",
    onClick() {
      createEstimateScopeButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Rate Sheet</h2>
      {renderEstimateScopeEditForm()}
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={estimateScopesDataSource}
        ref={estimateScopesDataGridRef}
        id={estimateScopesDataGridName}
        height={() => calculateGridHeight(estimateScopesDataGridName)}
      >
        <Toolbar>
          <Item
            name="groupPanel"
            location="before"
          />
          <Item
            location="after"
            widget="dxButton"
            options={createEstimateScopeButtonOptions}
          />
        </Toolbar>
        <Paging
          defaultPageSize={10}
          enabled={true}
        />
        <Pager
          visible={true}
        />
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <FilterRow visible={true} />
        <Grouping
          autoExpandAll={true}
          expandMode="rowClick"
        />
        <GroupPanel
          visible={true}
        />
        <Editing
          mode="popup"
          allowUpdating={false}
          allowAdding={false}
          allowDeleting={true}
        >
        </Editing>
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            icon="edit"
            onClick={(e) => estimateScopesGridOnEditingStart(e)}
          />
        </Column>
        <Column dataField="name" sortIndex={0} sortOrder="asc" />
        <Column dataField="estimateCategoryName" groupIndex={0} />
        <Column
          name="deleteButton"
          type="buttons"
        >
          <Button
            icon="trash"
            name="delete"
          />
        </Column>

      </DataGrid>
    </React.Fragment>
  );
}