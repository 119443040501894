import React,
{
  useRef,
  useState,
}
  from "react";
import DataGrid, {
  Column,
  Paging,
  Button,
  Editing,
  Scrolling,
  Toolbar,
} from "devextreme-react/data-grid";
import TabPanel, {
  Item
} from "devextreme-react/tab-panel";
import {
  Popup,
  ToolbarItem
} from 'devextreme-react/popup';
import Form, {
  RequiredRule,
} from "devextreme-react/form";
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";
import { ManyToManyGrid } from '../../components/many-to-many-grid/many-to-many-grid';

export default function EstimateCategories(props) {
  const estimateCategoriesApiService = new props.estimateCategoriesApiService();
  const estimateCategoriesDataSource = estimateCategoriesApiService.getEstimateCategoriesDataSource();

  const estimateCategoriesDataGridName = "EstimateCategoriesDataGrid";
  const estimateCategoriesDataGridRef = useRef(null);

  const [showEstimateCategoryEditForm, setShowEstimateCategoryEditForm] = useState();
  const [editingEstimateCategoryViewData, setEditingEstimateCategoryViewData] = useState();
  const editingEstimateCategoryDtoData = useRef(
    {
      subtrades: {
        toAdd: [],
        toRemove: []
      }
    }
  );
  const [isCreate, setIsCreate] = useState(false);
  const editFormTitle = useRef('');
  const editFormRef = useRef();
  const addSubtradesFormTitle = useRef('');

  const estimateCategoryEditFormSaveButtonOptions = {
    text: "Save",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      estimateCategoryEditFormSaveButtonOnClick();
    }
  }

  async function estimateCategoryEditFormSaveButtonOnClick() {
    var formInstance = editFormRef.current.instance;
    var validationResult = formInstance.validate();
    if (validationResult.isValid) {
      await saveEstimateCategoryData()
        .then(() => {
          cleanupAndCloseEditForm();
        });
    }
  }

  async function saveEstimateCategoryData() {
    if (isCreate) {
      await estimateCategoriesApiService.createEstimateCategory(editingEstimateCategoryDtoData.current);
    }
    else {
      await estimateCategoriesApiService.updateEstimateCategory(editingEstimateCategoryViewData.id, editingEstimateCategoryDtoData.current);
    }
  }

  function cleanupAndCloseEditForm() {
    setIsCreate(false);
    setShowEstimateCategoryEditForm(false);
    setEditingEstimateCategoryViewData();
    resetEstimateCategoryDto();
  }

  const estimateCategoryEditFormCancelButtonOptions = {
    text: "Cancel",
    type: "default",
    stylingMode: "outlined",
    onClick() {
      estimateCategoryEditFormCancelButtonOnClick();
    }
  }

  function estimateCategoryEditFormCancelButtonOnClick() {
    cleanupAndCloseEditForm();
  }

  function estimateCategoryFormFieldDataChanged(e) {
    if (e.dataField === "name") {
      var dtoHasName = editingEstimateCategoryDtoData.current.hasOwnProperty("name");
      if (!dtoHasName) {
        Object.defineProperty(editingEstimateCategoryDtoData.current, "name", { value: "", writable: true, enumerable: true });
      }
      editingEstimateCategoryDtoData.current.name = e.value;
    }
  }

  const manyToManyGridColumns = [
    <Column
      dataField="name"
      sortIndex={0}
      sortOrder="asc"
    />
  ];

  function renderEstimateCategoryEditForm() {
    if (showEstimateCategoryEditForm) {
      return (
        <Popup
          fullScreen={true}
          visible={showEstimateCategoryEditForm}
          showCloseButton={true}
          onHiding={() => {
            cleanupAndCloseEditForm();
          }}
          title={editFormTitle.current}
        >
          <TabPanel>
            <Item title="Properties">
              <Form
                id="editingEstimateCategoryForm"
                formData={editingEstimateCategoryViewData}
                onFieldDataChanged={estimateCategoryFormFieldDataChanged}
                ref={editFormRef}
              >
                <Item
                  dataField="name"
                >
                  <RequiredRule />
                </Item>
              </Form>
            </Item>
            <Item title="Sub-trades">
              <div className="spacedDiv">This grid displays all the subtrades that will show under the 'Subtrade' group on the Estimate Sheet for this category.</div>
              <ManyToManyGrid
                relatedGridDataSource={editingEstimateCategoryViewData.relatedSubtrades.$values}
                unrelatedGridDataSource={editingEstimateCategoryViewData.unrelatedSubtrades.$values}
                addButtonText="Add Subtades to Category..."
                removeMultipleRelatedButtonText="Remove Selected Subtrades..."
                removeMultipleMessage="Are you sure you want to remove the selected subtrades from this Category?"
                confirmDeleteMessage="Are you sure you want to remove this subtrade from the Category?"
                addUnrelatedPopupHeaderTitle={addSubtradesFormTitle.current}
                relatedObjectsToAddCollection={editingEstimateCategoryDtoData.current.subtrades.toAdd}
                relatedObjectsToRemoveCollection={editingEstimateCategoryDtoData.current.subtrades.toRemove}
                relatedGridColumns={manyToManyGridColumns}
                unrelatedGridColumns={manyToManyGridColumns}
                message="NOTE: Only compaines marked as 'Subtrade' show in this list."
              />
            </Item>
          </TabPanel>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateCategoryEditFormSaveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={estimateCategoryEditFormCancelButtonOptions}
          />
        </Popup>
      );
    }
  }

  async function estimateCategoryGridOnEditingStart(e) {
    setIsCreate(false);
    await loadEstimateCategoryData(e.row.data.id)
      .then(() => {
        setShowEstimateCategoryEditForm(true);
      });
    editFormTitle.current = "Editing: " + e.row.data.name;
    addSubtradesFormTitle.current = "Add Subtrades to Category: " + e.row.data.name;
  }

  async function loadEstimateCategoryData(id) {
    var estimateCategoryData = await estimateCategoriesApiService.getEstimateCategoryDetails(id);
    setEditingEstimateCategoryViewData(estimateCategoryData);
  }

  function resetEstimateCategoryDto() {
    editingEstimateCategoryDtoData.current = {
      subtrades: {
        toAdd: [],
        toRemove: []
      }
    }
  }

  async function createEstimateCategoryButtonClick() {
    await loadEstimateCategoryData(0)
      .then(() => {
        editFormTitle.current = "New Estimate Category";
        addSubtradesFormTitle.current = "Add Subtrades to New Category";
        setIsCreate(true);
        setShowEstimateCategoryEditForm(true);
      });
  }

  const createEstimateCategoryButtonOptions = {
    text: '+',
    onClick() {
      createEstimateCategoryButtonClick();
    }
  }

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Estimate Categories</h2>
      { renderEstimateCategoryEditForm() }
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={estimateCategoriesDataSource}
        ref={estimateCategoriesDataGridRef}
        id={estimateCategoriesDataGridName}
        height={() => calculateGridHeight(estimateCategoriesDataGridName) }
      >
        <Paging defaultPageSize={50} />
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <Editing
          mode="popup"
          allowUpdating={false}
          allowAdding={false}
          allowDeleting={true}
        >
        </Editing>
        <Toolbar>
          <Item
            location="after"
            widget="dxButton"
            options={createEstimateCategoryButtonOptions}
          />
        </Toolbar>
        <Column
          name="editButton"
          type="buttons"
        >
          <Button
            icon="edit"
            onClick={(e) => estimateCategoryGridOnEditingStart(e)}
          />
        </Column>
        <Column dataField="name" sortIndex={0} sortOrder="asc" />
      </DataGrid>
    </React.Fragment>
  );
}